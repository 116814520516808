exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-אודותינו-js": () => import("./../../../src/pages/אודותינו.js" /* webpackChunkName: "component---src-pages-אודותינו-js" */),
  "component---src-pages-איך-זה-עובד-js": () => import("./../../../src/pages/איך-זה-עובד.js" /* webpackChunkName: "component---src-pages-איך-זה-עובד-js" */),
  "component---src-pages-מחשבון-הזהב-js": () => import("./../../../src/pages/מחשבון-הזהב.js" /* webpackChunkName: "component---src-pages-מחשבון-הזהב-js" */),
  "component---src-pages-צור-קשר-js": () => import("./../../../src/pages/צור-קשר.js" /* webpackChunkName: "component---src-pages-צור-קשר-js" */)
}

