import React from "react";
import {ConfigProvider} from 'antd-mobile';
import {RatesProvider} from "./src/context/ratesContext";
import 'antd-mobile/bundle/style.css';
import './src/bootstrap-grid.rtl.css'
import './src/custom.css'
import {SettingsProvider} from "./src/context/settingsContext";

export const wrapRootElement = ({element}) => {
    return (
        <ConfigProvider>
            <RatesProvider>
                <SettingsProvider>
                    {element}
                </SettingsProvider>
            </RatesProvider>
        </ConfigProvider>
    )
};
