import React from 'react'
import * as apiClient from '../utils/apiClient'

const SettingsContext = React.createContext({});

function SettingsProvider({ssr = false, children}) {

    const [settings, setSettings] = React.useState({})
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (ssr)
            return
        setLoading(true)

        apiClient.client('settings')
            .then(settings => setSettings(settings))
            .catch(error => setError(error.message))
            .then(() => setLoading(false))
    }, []) // eslint-disable-line

    return (
        <SettingsContext.Provider
            value={{
                settings,
                error,
                loading
            }}>
            {children}
        </SettingsContext.Provider>
    )
}

function useSettings() {
    const context = React.useContext(SettingsContext);

    if (context === undefined) {
        throw new Error(`useSettings must be used within a SettingsContext`)
    }

    return context
}

export {SettingsProvider, useSettings}
